<template>
    <div class="page-outer-container">
        <section v-if="record" class="page-inner-container">
            <div class="page-inner-container-content">
                <div class="columns align-items-center justify-content-center">
                    <div class="column flex-static">
                        <base-link
                            :href="is_walker ? { name: 'walker.profile' } : { name: 'team.edit', params: { id: record.uuid } }"
                        >
                            <avatar
                                :url="avatar.url"
                                :initials="record_initials"
                                color="gray"
                            />
                        </base-link>
                    </div>
                    <div class="column flex-static">
                        {{ $root.translate("Hi, {name}", { name: full_name(record) || record.name }) }}
                        <div v-if="!!team" class="text-normal">
                            <span v-if="!!record.team_captain">{{ $root.translate("I'm captain of team \"{name}\"!", { name: team.name }) }}</span>
                            <span v-else>{{ $root.translate("I'm part of team \"{name}\"!", { name: team.name }) }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!is_walker || (record.team_captain && team && team.id)" class="text-center mt-5">
                    <label
                        v-if="!record.is_verified"
                        class="form-label text-center text-small"
                    >
                        {{ $root.translate("Your email needs to be verified before you can invite team members") }}
                    </label>
                    <base-link
                        v-else
                        :href="{ name: 'team.edit', params: { id: is_walker ? team.uuid : record.uuid }, hash: '#invite-card' }"
                        class="btn btn-light btn-sm text-normal text-small"
                    >
                        {{ $root.translate("Invite team members") }}
                    </base-link>
                </div>
            </div>
        </section>
        <section v-if="record" class="page-inner-container walker-stats">
            <div class="page-inner-container-content">
                <!--
                <div class="walker-stat walker-stat-lg">
                    <div>
                        {{ record.computed_total_trips | nibnut.number("0,0") }}
                        <div class="text-small">{{ $root.translate("trip walked:::trips walked", {}, record.computed_total_trips) }}</div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="walker-stat">
                            <span v-if="total_distance < 1000">
                                {{ total_distance | nibnut.number("0,0") }}<span>m</span>
                            </span>
                            <span v-else>
                                {{ total_distance / 1000 | nibnut.number("0,0.0") }}<span>km</span>
                            </span>
                            <div class="text-small">{{ $root.translate("distance walked") }}</div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="walker-stat">
                            {{ record.travel_days.length | nibnut.number("0,0") }}
                            <div class="text-small">{{ $root.translate("days walked") }}</div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="walker-stat">
                            {{ steps | nibnut.number("0,0") }}
                            <div class="text-small">{{ $root.translate("steps taken") }}</div>
                        </div>
                    </div>
                </div>
                //-->
                <dashboard-stats-panel
                    :trips="record.computed_total_trips"
                    :distance="total_distance"
                    :days="record.travel_days.length"
                    :steps="steps"
                />
                <h5 v-if="!!record && is_walker" class="log-trip mt-8 mb-4">{{ $root.translate("Log a new walk!") }}</h5>
                <div v-if="!!record && is_walker" class="text-center">
                    <add-trip-button
                        :walker-id="record.id"
                    />
                </div>
            </div>
        </section>
        <section v-if="record" class="page-inner-container savings">
            <div class="page-inner-container-content">
                <h5>{{ $root.translate("Environmental Benefit") }}</h5>
                <h4 v-if="ghgs < 1"><span class="text-large">{{ ghgs * 1000 | nibnut.number("0,0") }}</span>g</h4>
                <h4 v-else-if="ghgs < 1000"><span class="text-large">{{ ghgs | nibnut.number("0,0.0") }}</span>kg</h4>
                <h4 v-else><span class="text-large">{{ ghgs | nibnut.number("0,0") }}</span>kg</h4>
                <h5>{{ $root.translate("of GHG averted!!") }}</h5>
            </div>
        </section>
        <slot></slot>
    </div>
</template>

<script>
import is_avatar_owner from "@/custom/mixins/IsAvatarOwner"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
import calculator_utilities from "@/custom/mixins/CalculatorUtilities"

import BaseLink from "@/nibnut/components/Links/BaseLink"
import DashboardStatsPanel from "@/custom/components/DashboardStatsPanel"
import AddTripButton from "@/custom/components/AddTripButton"

export default {
    name: "DashboardWrapper",
    mixins: [is_avatar_owner, addl_profile_utilities, calculator_utilities],
    components: {
        BaseLink,
        DashboardStatsPanel,
        AddTripButton
    },
    computed: {
        is_walker () {
            return this.entity === "walker"
        },
        record_initials () {
            return this.initials(this.record)
        },
        avatar () {
            if(!this.record) return {}
            return this.avatar_by_id(this.record.avatar_id)
        },
        team () {
            if(!this.record || !this.is_walker) return null
            return this.entity_records("team").find(team => team.id === this.record.team_id)
        },
        total_distance () {
            if(!this.record) return 0
            return this.standardized_distance(this.record.computed_total_distance)
        },
        steps () {
            if(!this.record) return 0
            return this.steps_for_distance(this.record.computed_total_distance)
        },
        ghgs () {
            if(!this.record) return 0
            return this.ghgs_for_distance(this.record.computed_total_distance) // in kg
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        entity: {
            type: String,
            validator: prop => !!prop && prop.match(/^(walker|team)$/)
        }
    }
}
</script>
