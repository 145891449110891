<template>
    <div class="dashboard-stats-panel">
        <div class="walker-stat walker-stat-lg">
            <div>
                {{ trips | nibnut.number("0,0") }}
                <div class="text-small">{{ $root.translate("trip walked:::trips walked", {}, trips) }}</div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="walker-stat">
                    <span v-if="distance < 1000">
                        {{ distance | nibnut.number("0,0") }}<span>m</span>
                    </span>
                    <span v-else>
                        {{ distance / 1000 | nibnut.number("0,0.0") }}<span>km</span>
                    </span>
                    <div class="text-small">{{ $root.translate("distance walked") }}</div>
                </div>
            </div>
            <div class="column">
                <div class="walker-stat">
                    {{ days | nibnut.number("0,0") }}
                    <div class="text-small">{{ $root.translate("days walked") }}</div>
                </div>
            </div>
            <div class="column">
                <div class="walker-stat">
                    {{ steps | nibnut.number("0,0") }}
                    <div class="text-small">{{ $root.translate("steps taken") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DashboardStatsPanel",
    props: {
        trips: {
            type: Number,
            default: 0
        },
        distance: {
            type: Number,
            default: 0
        },
        days: {
            type: Number,
            default: 0
        },
        steps: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.dashboard-stats-panel {
    text-align: center;

    .columns {
        justify-content: center;
        .column {
            flex: 0 0 6rem;
            @media (max-width: $size-sm) {
                margin-bottom: 0.8rem;
            }
        }
    }
    .walker-stat {
        display: inline-block;
        font-size: 1.4rem;
        font-family: "Hop";
        font-weight: normal;
        line-height: 1rem;

        & > span:last-child {
            font-size: 0.7rem;
        }
        & > .text-small {
            font-size: 0.6rem;
        }

        &.walker-stat-lg {
            position: relative;
            font-size: 3.6rem;
            width: 6rem;
            height: 6rem;
            line-height: 1.8rem;
            margin-bottom: 0.6rem;

            & > div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -35%);
                z-index: 1;
                white-space: nowrap;
                & > .text-small {
                    font-size: 0.9rem;
                }
            }
            &:before {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0.5rem solid $gray-color-light;
                border-radius: 50%;
                z-index: 0;
            }
        }
    }
}
</style>
