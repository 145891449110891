<template>
    <form-select
        v-bind="select_props"
        data-source="school"
        :empty-value="null"
        :show-all="false"
        :min-option-query-length="2"
        v-on="select_events"
        @displayed="update_display_value"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    class="form-label disabled-field"
                >
                    {{ display_value }}
                </label>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint>
            <div v-if="!!displayed_option && !!displayed_option.schoolboard_name" class="text-small">{{ displayed_option.schoolboard_name }}</div>
        </template>
        <template v-slot:option="{ option, highlight }">
            <span v-html="highlight"></span>
            <div v-if="!!option.schoolboard_name" class="text-small">{{ option.schoolboard_name }}</div>
        </template>
        <template v-slot:right_addon="{ option }"><slot name="right_addon" :option="option"></slot></template>
    </form-select>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormSelect from "@/nibnut/components/Inputs/FormSelect"

export default {
    name: "SchoolPicker",
    mixins: [is_nibnut_component],
    components: {
        FormSelect
    },
    methods: {
        update_display_value (displayed_option) {
            this.displayed_option = displayed_option
        }
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        select_props () {
            const { dataSource, emotyValue, showAll, minOptionQueryLength, ...select_props } = this.$props
            return select_props
        },
        select_events () {
            const { displayed, ...select_events } = this.$listeners
            return select_events
        },
        display_value () {
            if(this.displayed_option) return this.displayed_option[this.labelField]
            return null
        }
    },
    props: {
        ...FormSelect.props
    },
    data () {
        return {
            displayed_option: null
        }
    }
}
</script>
