<template>
    <modal-dialog
        id="invite-user"
        :dismissable="!inviting"
        :show.sync="shown"
    >
        <template v-slot:title>
            <span v-if="is_regional_admin" class="h5">{{ $root.translate("Invite Team Captain") }}</span>
            <span v-else class="h5">{{ $root.translate("Invite User") }}</span>
        </template>
        <base-form
            :has-required-fields="true"
            @submit.prevent="send_invitation"
        >
            <div class="columns">
                <div
                    :class="{ 'col-6': !is_regional_admin, 'col-12': is_regional_admin }"
                    class="column col-md-12 mb-2"
                >
                    <form-input
                        id="email"
                        type="email"
                        name="email"
                        v-model="user.email"
                        :required="true"
                        :error="has_error('email')"
                    >
                        <template v-slot:label>{{ $root.translate("Email") }}</template>
                    </form-input>
                </div>
                <div
                    v-if="!is_regional_admin"
                    class="column col-6 col-md-12 mb-2"
                >
                    <form-select
                        id="role"
                        name="role"
                        v-model="user.role"
                        :data-source="filtered_roles"
                        :required="true"
                        :error="has_error('role')"
                    >
                        <template v-slot:label>{{ $root.translate("Role") }}</template>
                    </form-select>
                </div>
                <div v-if="user.role >= constants('roles', 'ROLE_CAPTAIN').id" class="column col-12">
                    <form-input
                        id="team_name"
                        name="team_name"
                        v-model="user.team_name"
                        :required="false"
                        :error="has_error('team_name')"
                    >
                        <template v-slot:label>{{ $root.translate("Team Name") }}</template>
                        <template v-slot:hint>
                            <small>
                                {{ $root.translate("If provided, when the user signs up, that team willl be automatically created and the user made its captain.") }}
                            </small>
                        </template>
                    </form-input>
                </div>
                <div v-if="user.role >= constants('roles', 'ROLE_CAPTAIN').id" class="column col-12">
                    <school-picker
                        id="team_school_id"
                        name="team_school_id"
                        v-model="user.team_school_id"
                        :required="false"
                        :disabled="!user.team_name"
                        :error="has_error('team_school_id')"
                    >
                        <template v-slot:label>{{ $root.translate("School Name") }}<span v-if="!!user.team_name"> *</span></template>
                    </school-picker>
                </div>
            </div>

            <div class="columns">
                <div class="column col-12">
                    <invitation-expiries
                        id="expires_in"
                        name="expires_in"
                        v-model="user.expires_in"
                        :required="true"
                        :error="has_error('expires_in')"
                    />
                </div>
            </div>
        </base-form>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    :waiting="inviting"
                    :disabled="inviting"
                    color="primary"
                    @click.prevent="send_invitation"
                >
                    {{ $root.translate("Send Invitation") }}
                </default-button>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import { mapState } from "vuex"

import ui_utilities from "@/nibnut/mixins/UiUtilities"
import handles_errors from "@/nibnut/mixins/HandlesErrors"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import InvitationExpiries from "@/nibnut/components/Inputs/InvitationExpiries"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

import SchoolPicker from "@/custom/components/SchoolPicker"

export default {
    name: "InviteUserDialog",
    mixins: [addl_profile_utilities, ui_utilities, handles_errors],
    components: {
        DefaultButton,
        ModalDialog,
        BaseForm,
        FormInput,
        FormSelect,
        InvitationExpiries,
        SchoolPicker
    },
    methods: {
        send_invitation () {
            this.inviting = true
            return this.$store.dispatch("CREATE_RECORD", {
                entity: "user",
                data: this.user
            }).then(record => {
                this.$success(this.$root.translate("Invitation email sent!"))
                this.shown = false
            }).catch(this.receive_error).then(() => {
                this.inviting = false
            })
        }
    },
    computed: {
        ...mapState(["app_context"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        filtered_roles () {
            return this.roles.filter(role => role.id !== this.constants("roles", "ROLE_DEVELOPER").id)
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            inviting: false
        }
    }
}
</script>
