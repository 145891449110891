<template>
    <div>
        <form-input
            :id="field_id('first_name')"
            v-model="record.first_name"
            name="first_name"
            :required="true"
            :placeholder="`${$root.translate('First Name')}*`"
            :disabled="disabled"
            :saving="saving('first_name')"
            :error="has_error('first_name')"
            v-on="$listeners"
        />
        <form-input
            :id="field_id('last_name')"
            v-model="record.last_name"
            name="last_name"
            :required="true"
            :maxlength="1"
            :placeholder="`${$root.translate('Last Name Initial')}*`"
            :disabled="disabled"
            :saving="saving('last_name')"
            :error="has_error('last_name')"
            v-on="$listeners"
        />
        <form-input
            :id="field_id('year_of_birth')"
            v-model="record.year_of_birth"
            type="number"
            name="year_of_birth"
            :required="true"
            :min="min_year_of_birth"
            :max="max_year_of_birth"
            :placeholder="`${$root.translate('Year of birth')}*`"
            glyph="calendar"
            glyph-library="hop"
            glyph-size="lg"
            :disabled="disabled"
            :saving="saving('year_of_birth')"
            :error="has_error('year_of_birth')"
            v-on="$listeners"
        />
        <school-picker
            v-if="canSetSchool"
            :id="field_id('school_id')"
            v-model="record.school_id"
            name="school_id"
            :required="true"
            :saving="saving('school_id')"
            :error="has_error('school_id')"
            v-on="$listeners"
        >
            <template v-slot:label>{{ $root.translate("Which school does the walker attend?") }}</template>
        </school-picker>
        <slot></slot>
        <div v-if="!disabled && canPickAvatar" class="text-center mt-5 mb-10">
            <div v-if="!!avatar.url">
                <avatar
                    :url="avatar.url"
                    :initials="record_initials"
                />
            </div>
            <default-button
                color="light"
                @click="$emit('pick-avatar')"
            >
                {{ $root.translate("Pick an avatar!") }}
            </default-button>
        </div>
    </div>
</template>

<script>
import getYear from "date-fns/getYear"

import ui_utilities from "@/nibnut/mixins/UiUtilities"
import { is_avatar_owner } from "@/custom/mixins"

import {
    FormInput,
    DefaultButton
} from "@/nibnut/components"
import SchoolPicker from "@/custom/components/SchoolPicker"

export default {
    name: "WalkerEditor",
    mixins: [ui_utilities, is_avatar_owner],
    components: {
        FormInput,
        DefaultButton,
        SchoolPicker
    },
    methods: {
        field_id (name) {
            if(this.fieldIdSuffix) return `${name}-${this.fieldIdSuffix}`
            if(!this.record || !this.record.id) return name
            return `${name}-${this.record.id}`
        },
        saving (field = "") {
            if(!field) return !!this.savingFieldIds.length
            return (this.savingFieldIds.indexOf(field) >= 0)
        },
        has_error (error_id) {
            if(error_id) return this.errors[error_id]
            return !!Object.keys(this.errors).length
        }
    },
    computed: {
        min_year_of_birth () {
            return getYear(new Date()) - 100
        },
        max_year_of_birth () {
            return getYear(new Date()) - 3
        },
        record_initials () {
            return this.initials(this.record)
        },
        avatar () {
            if(!this.record) return {}
            return this.avatar_by_id(this.record.avatar_id)
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        canPickAvatar: {
            type: Boolean,
            default: false
        },
        canSetSchool: {
            type: Boolean,
            default: true
        },
        savingFieldIds: {
            type: Array,
            default () {
                return []
            }
        },
        errors: {
            type: Object,
            default () {
                return {}
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fieldIdSuffix: {
            type: String,
            default: ""
        }
    }
}
</script>
